import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Button, Pagination, SelectPageSize } from "nlib/ui";
import { checkAuditFetching, getAuditDataCount, getAuditUnusualAmountsData } from "selectors/audit";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import AuditActions from "actions/AuditActions";
import Constants from "const/Constants";
import React, { useCallback, useEffect } from "react";
import SubTable from "./lib/SubTable";
import Table, { TableCell, TableHead, TableRow } from "nlib/ui/Table";
import Utils from "utils/Utils";
import useEnvVars from "hooks/useEnvVars";
import useShowCommonModal from "hooks/useShowCommonModal";

const { TABLE_PAGE_SIZE } = Constants;

const UnusualAmountTable = ({ children, disabled, currentRoute, refetchData }) => {
  const dispatch = useDispatch();

  const showCommonModal = useShowCommonModal();

  const [{ page, pageSize = TABLE_PAGE_SIZE }, setEnvVars] = useEnvVars();

  const { uiTexts, messages } = useSelector(getTextsData);

  const data = useSelector(getAuditUnusualAmountsData);

  const count = useSelector(getAuditDataCount);

  const auditFetchings = useSelector(checkAuditFetching);

  const handlePageChange = useCallback((value) => {
    setEnvVars({ page: value });
  }, [setEnvVars]);

  const handlePageSizeChange = useCallback((value) => {
    setEnvVars({ pageSize: value });
  }, [setEnvVars]);

  useEffect(() => {
    const scrollContainer = document.querySelector("#scroll-container");

    if (scrollContainer) scrollContainer.scrollTo(0, 0);
  }, [page]);

  const handleMarkAllAsCorrectClick = useCallback(async(event, id) => {
    const { account, category, types } = Utils.arrayFindById(data, id, {});

    const result = await showCommonModal({
      text: Utils.replaceTextVars(messages.bulkTransactionsMarkAsCorrectConfirm, {
        transactionsCount: types.reduce((aggregator, { transactionsCount }) => aggregator + transactionsCount, 0)
      }),
      confirm: true
    });

    if (result) {
      await dispatch(AuditActions.unusualMarkAsRevised({
        currentRoute,
        filters: {
          accountId: account.id,
          categoryCode: category.code
        }
      }));
      refetchData();
    }
  }, [currentRoute, data, dispatch, messages, refetchData, showCommonModal]);

  return (
    <div className={Css.unusualVendorCategoriesTable}>
      <div className={Css.content}>
        {children}
        <div className={Css.tableWrap}>
          <Table className={Css.table}>
            <TableRow>
              <TableHead>{`${uiTexts.account} / ${uiTexts.category}`}</TableHead>
            </TableRow>
            {data.map(({ id, account, category, types }) => (
              <TableRow key={id} className={Css.tableRow}>
                <TableCell className={Css.tableCell}>
                  <div className={Css.itemContainer}>
                    <div className={Css.header}>
                      <div className={Css.title}>
                        <Icons.CreditCard />
                        <span className={Css.part} title={account?.name}>
                          {account?.name}
                        </span>
                        <span className={Css.slash}>/</span>
                        <Icons.SquaresFour />
                        <span className={Css.part} title={category.displayName}>
                          {category.displayName}
                        </span>
                      </div>

                      <div className={Css.actions}>
                        <Button
                          outline success
                          disabled={auditFetchings}
                          icon={Icons.ThumbsUp}
                          value={id}
                          onClick={handleMarkAllAsCorrectClick}>
                          {uiTexts.markAllAsCorrect}
                        </Button>
                      </div>
                    </div>
                    <div className={Css.subTable}>
                      {types.map(({ type, share, transactionsCount }) => (
                        <SubTable
                          key={type}
                          disabled={disabled}
                          type={type}
                          share={share}
                          currentRoute={currentRoute}
                          transactionsCount={transactionsCount}
                          accountId={account.id}
                          categoryCode={category.code}
                          refetchAuditData={refetchData} />
                      ))}
                    </div>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </Table>
          <div className={Css.footer}>
            {count > +pageSize && (
              <Pagination
                className={Css.pagination}
                count={count}
                page={+page}
                pageSize={+pageSize}
                onChange={handlePageChange} />
            )}
            <SelectPageSize
              pageSize={+pageSize}
              onChange={handlePageSizeChange} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(UnusualAmountTable);
