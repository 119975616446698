import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Button, Pagination, SelectPageSize } from "nlib/ui";
import { checkAuditFetching, getAuditDataCount, getAuditUnusualVendorsData } from "selectors/audit";
import { getCurrentXeroOrganizationId } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import AuditActions from "actions/AuditActions";
import Constants from "const/Constants";
import MergeModal from "./lib/MergeModal";
import React, { useCallback, useEffect } from "react";
import SubTable from "./lib/SubTable";
import Table, { TableCell, TableHead, TableRow } from "nlib/ui/Table";
import Utils from "utils/Utils";
import useEnvVars from "hooks/useEnvVars";
import useShowCommonModal from "hooks/useShowCommonModal";
import useShowModal from "hooks/useShowModal";

const { TABLE_PAGE_SIZE } = Constants;

const UnusualCategoriesTable = ({ children, disabled, currentRoute, refetchData }) => {
  const dispatch = useDispatch();

  const [
    mergeModalShown,
    showMergeModal,
    onCloseMergeModal
  ] = useShowModal();

  const showCommonModal = useShowCommonModal();

  const [{ page, pageSize = TABLE_PAGE_SIZE }, setEnvVars] = useEnvVars();

  const { uiTexts, messages } = useSelector(getTextsData);

  const data = useSelector(getAuditUnusualVendorsData);

  const count = useSelector(getAuditDataCount);

  const xeroBusiness = !!useSelector(getCurrentXeroOrganizationId);

  const auditFetchings = useSelector(checkAuditFetching);

  const handlePageChange = useCallback((value) => {
    setEnvVars({ page: value });
  }, [setEnvVars]);

  const handlePageSizeChange = useCallback((value) => {
    setEnvVars({ pageSize: value });
  }, [setEnvVars]);

  const handleMergeClick = useCallback(async(event, id) => {
    const { categories = [], address = {} } = Utils.arrayFindById(data, id, {});

    const modalResult = await showMergeModal({ categories, addressName: address.name });

    if (modalResult) {
      await dispatch(AuditActions.unusualBulkUpdate({
        currentRoute,
        filters: {
          addressName: address.name,
          categoryCode: categories.map(({ category: item }) => item.code).filter((item) => item !== modalResult.code)
        },
        updateData: {
          category: modalResult,
          tags: [modalResult.name]
        }
      }));

      refetchData();
    }
  }, [currentRoute, data, dispatch, refetchData, showMergeModal]);

  const handleMarkAllAsCorrectClick = useCallback(async(event, id) => {
    const { categories = [], address = {} } = Utils.arrayFindById(data, id, {});

    if (categories.length) {
      const result = await showCommonModal({
        text: Utils.replaceTextVars(messages.bulkTransactionsMarkAsCorrectConfirm, {
          transactionsCount: categories.reduce((aggregator, { transactionsCount }) => aggregator + transactionsCount, 0)
        }),
        confirm: true
      });

      if (result) {
        await dispatch(AuditActions.unusualMarkAsRevised({
          currentRoute,
          filters: {
            addressName: address.name,
            categoryCode: categories.map(({ category: item }) => item.code)
          }
        }));
        refetchData();
      }
    }
  }, [currentRoute, data, dispatch, messages, refetchData, showCommonModal]);

  useEffect(() => {
    const scrollContainer = document.querySelector("#scroll-container");

    if (scrollContainer) scrollContainer.scrollTo(0, 0);
  }, [page]);

  return (
    <>
      <div className={Css.unusualVendorCategoriesTable}>
        <div className={Css.content}>
          {children}
          <div className={Css.tableWrap}>
            <Table className={Css.table}>
              <TableRow>
                <TableHead>{`${uiTexts.payee} / ${uiTexts.category}`}</TableHead>
              </TableRow>
              {data.map(({ id, address, categories }) => (
                <TableRow key={id} className={Css.tableRow}>
                  <TableCell className={Css.tableCell}>
                    <div className={Css.itemContainer}>
                      <div className={Css.header}>
                        <div className={Css.title} title={address.name}>
                          <Icons.AddressBook />
                          <span>{address.name}</span>
                        </div>

                        <div className={Css.actions}>
                          {!xeroBusiness && (
                            <Button
                              outline primary
                              disabled={auditFetchings}
                              value={id}
                              className={Css.mergeButton}
                              icon={Icons.UniteSquare}
                              onClick={handleMergeClick}>
                              {uiTexts.mergeCategories}
                            </Button>
                          )}
                          <Button
                            outline success
                            disabled={auditFetchings}
                            value={id}
                            icon={Icons.ThumbsUp}
                            onClick={handleMarkAllAsCorrectClick}>
                            {uiTexts.markAllAsCorrect}
                          </Button>
                        </div>
                      </div>
                      <div className={Css.subTable}>
                        {categories.map(({ category, share, transactionsCount }) => (
                          <SubTable
                            key={category.code}
                            disabled={disabled}
                            addressName={address.name}
                            category={category}
                            share={share}
                            currentRoute={currentRoute}
                            transactionsCount={transactionsCount}
                            refetchAuditData={refetchData} />
                        ))}
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </Table>
            <div className={Css.footer}>
              {count > +pageSize && (
                <Pagination
                  className={Css.pagination}
                  count={count}
                  page={+page}
                  pageSize={+pageSize}
                  onChange={handlePageChange} />
              )}
              <SelectPageSize
                pageSize={+pageSize}
                onChange={handlePageSizeChange} />
            </div>
          </div>
        </div>
      </div>
      {mergeModalShown && (
        <MergeModal {...mergeModalShown} onClose={onCloseMergeModal} />
      )}
    </>
  );
};

export default React.memo(UnusualCategoriesTable);
