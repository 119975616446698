import Css from "./style.module.scss";

import { XERO_EXCLUDED_SECTIONS } from "nlib/pages/AuditPage/AuditSections";
import { checkAuditFetching, getAuditStats } from "selectors/audit";
import { getCurrentQuickBooksRealmId, getCurrentXeroOrganizationId, getSelectedBusinessData } from "selectors/businesses";
import { getCustomReportsData } from "selectors/customReports";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Constants from "const/Constants";
import React, { useMemo } from "react";
import classNames from "classnames";

const { AUDIT_SECTIONS_DATA } = Constants;

const AUDIT_SECTIONS_ARRAY = Object.values(AUDIT_SECTIONS_DATA.TRANSACTIONS);

const Progress = () => {
  const { uiTexts } = useSelector(getTextsData);

  const auditStats = useSelector(getAuditStats);

  const { auditSettings } = useSelector(getSelectedBusinessData);

  const quickBooksBusiness = !!useSelector(getCurrentQuickBooksRealmId);

  const xeroBusiness = !!useSelector(getCurrentXeroOrganizationId);

  const { settings: { advancedDocumentsWorkflow } = {} } = useSelector(getSelectedBusinessData);

  const customSections = useSelector(getCustomReportsData);

  const availableSections = AUDIT_SECTIONS_ARRAY.filter((key) => {
    return auditSettings[key]
      && (!xeroBusiness || !XERO_EXCLUDED_SECTIONS.includes(key))
      && (key !== AUDIT_SECTIONS_DATA.TRANSACTIONS.NO_ATTACHMENT || (!advancedDocumentsWorkflow && quickBooksBusiness));
  });

  const fetching = useSelector(checkAuditFetching);

  const percentage = useMemo(() => {
    if (!auditStats.transactions) return "0%";

    const result = Object.values(AUDIT_SECTIONS_DATA.TRANSACTIONS).reduce((aggregator, value) => {
      if (!availableSections.includes(value)) return aggregator;

      return [...aggregator, auditStats.transactions[value]?.count];
    }, []);

    customSections.forEach(({ id }) => {
      result.push(auditStats.transactions[id]?.count);
    });

    return `${
      Math.ceil(Constants.PERCENTS_MULTIPLIER - (result.filter(Boolean).length / result.length * Constants.PERCENTS_MULTIPLIER))
    }%`;
  }, [auditStats.transactions, availableSections, customSections]);

  return (
    <div className={classNames(Css.progress, fetching && Css.fetching)}>
      <div className={Css.title}>
        <div className={Css.text}>{uiTexts.completed}</div>
        <div className={Css.percentage}>{percentage}</div>
      </div>
      <div className={Css.bar}>
        <div className={Css.level} style={{ width: percentage }} />
      </div>
    </div>
  );
};

export default Progress;
